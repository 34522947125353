import { Component, Mixins } from "vue-property-decorator";
import { IOnboardAPI } from "@/services/api/types/OnboardAPI";
import { IBrand } from "@/types/whitelabel";
import { Action, Getter, State } from "vuex-class";
import { IBankLoanConfigStatusModel } from "@/types/models/BankLoanConfigStatus";
import { DialogLogic } from "@/mixins/DialogLogic";
import { DeviceLogic } from "@/mixins/DeviceLogic";

export type Optional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Type>;

@Component({})
export default class AbstractController extends Mixins(DialogLogic, DeviceLogic) {
  @Getter("whiteLabel") currentBrand!: IBrand;
  @Getter("onboardApi") OnboardAPI!: IOnboardAPI;
  @Getter("showHelpButtons") showHelpButtons!: boolean;

  @Action("User/setDataUser") setDataUser!: (e?: any) => any;

  @State((state) => state.Proposal.bankLoanConfigStatus)
  bankLoanConfigStatus!: IBankLoanConfigStatusModel;

  get isMotorolaWhiteLabel() {
    return ["motorola", "johndeere"].includes(this.currentBrand.brand);
  }

  loading = true;

  async created() {
    await this.mountHandler();
    this.loading = false;
  }

  async mountHandler() {}

  get isAdminUser() {
    return this.$store.getters["User/isAdminUser"];
  }

  get userGroup() {
    const { groups = [] } = this.$store.state.User.user;
    const availableGroups = ["ug_bank_customer", "admin", "super_admin", "borrower"];

    const foundUserGroup = groups.find((item: any) => availableGroups.includes(item.code));

    return foundUserGroup || {};
  }

  get allowUserHideTutorials() {
    return this.currentBrand.additionalConfigs?.showRemoveTutorialsButton ?? true;
  }

  isProposalTutorialHidden() {
    return localStorage.getItem("hideProposalTutorial") === "true";
  }

  clearUser() {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("startTime");
    localStorage.removeItem("externalAuthError");
    localStorage.removeItem("proposal_id");
    this.setDataUser();
  }

  logoutHandler() {
    this.clearUser();

    if (this.currentBrand.brand === "dinheirow") {
      return this.$router.push({ path: this.currentBrand.logoutUrl });
    }

    return this.$router.push({
      name: "Redirect",
      params: { redirect: this.currentBrand.logoutUrl },
    });
  }

  async redirectToDashboard() {
    await this.$router.push({ name: "DashboardHome" });
  }

  get showProposalVerificationModal() {
    return this.currentBrand?.additionalConfigs?.showProposalVerificationModal;
  }
}
